<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      sticky-header="100%"
      head-variant="light"
      responsive
      bordered
      small
      :filter="filter"
      :busy="loading || updating"
      stacked="md"
    >
      <template #table-busy>
        <div
          class="d-flex align-items-center justify-content-center text-black"
          style="min-height: 50vh"
        >
          <b-spinner class="mr-3"></b-spinner>
          <strong>
            {{
              loading ? 'Cargando información, por favor espere...' : 'Actualizando información...'
            }}
          </strong>
        </div>
      </template>

      <template #cell(proyecto)="data">
        <div class="cell--center">
          <b-form-select
            :id="`proyecto_${data.item.id}`"
            v-model="data.value.id"
            :options="proyectosOptions"
            @change="updateProyeccionPresupuesto($event, data.item)"
            size="sm"
          />
        </div>
      </template>

      <template #cell(unidad_administrativa)="data">
        <div class="cell--center">
          <b-form-select
            :id="`unidad_administrativa_${data.item.id}`"
            v-model="data.value.id"
            :options="unidadesAdministrativasOptions"
            @change="updateProyeccionPresupuesto($event, data.item)"
            size="sm"
          />
        </div>
      </template>

      <!-- Importe -->
      <template #cell(importe)="data">
        <div class="cell--center">
          <x-input-group-currency
            :item="data.item"
            :value="data.value"
            attach-value-to-payload
            attach-value-name="importe"
            size="sm"
            :payload="{
              id: data.item.id,
              payload: {
                mes: data.item.mes,
                proyecto_id: data.item.proyecto.id,
                unidad_administrativa_id: data.item.unidad_administrativa.id
              }
            }"
            dispatch-path="planeacionModule/editarProyeccion"
            @on-dispatched="onUpdatedImporte"
          />
        </div>
      </template>

      <template #cell(acciones)="data">
        <div class="cell--center">
          <u-d-controls
            :id="data.item.id"
            editPath=""
            hide-edit-action
            deletePath="planeacionModule/eliminarProyeccion"
            @on-deleted="onUpdatedImporte"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import XInputGroupCurrency from '@/components/Shared/XInputGroupCurrency'
import { mapGetters } from 'vuex'

const formValues = () => ({})
export default {
  name: 'ProyeccionDesgloseList',

  components: {
    XInputGroupCurrency
  },

  props: {
    updateData: {
      type: Object,
      required: false,
      default: () => ({})
    },

    filter: {
      type: String,
      required: false,
      default: null
    },

    periodo_fiscal_id: {
      type: Number,
      required: true
    }
  },

  async created () {
    console.log('created', this.periodo_fiscal_id)
    console.log('getProyeccionPeriodo')
    this.getProyeccionPeriodo()
    console.log('getAdministrativeUnits')
    this.$store.dispatch('administrativeUnitModule/getAdministrativeUnits')
    console.log('getProyectos')
    this.$store.dispatch('proyectoModule/getProyectos', this.periodo_fiscal_id)
  },

  data () {
    return {
      fields: [
        {
          key: 'proyecto',
          label: 'Proyecto'
        },
        {
          key: 'unidad_administrativa',
          label: 'Unidad administrativa'
        },
        {
          key: 'importe',
          label: 'Importe'
        },
        {
          key: 'acciones',
          label: 'Acciones'
        }
      ],

      formValues: formValues(),

      items: [],

      loading: false,

      updating: false
    }
  },

  computed: {
    ...mapGetters('administrativeUnitModule', ['getAdministrativeUnits']),

    ...mapGetters('proyectoModule', ['getAllProyectos']),

    proyectosOptions () {
      const list = this.getAllProyectos

      return this.getListToSelect(list, 'id', 'nombre')
    },

    unidadesAdministrativasOptions () {
      const list = this.getAdministrativeUnits

      return this.getListToSelect(list, 'id', 'nombre')
    }
  },

  methods: {
    async getProyeccionPeriodo () {
      this.loading = true
      console.log(this.updateData)
      const getval = await this.$store.dispatch(
        'planeacionModule/getProyeccionPeriodoByMes',
        this.updateData
      )
      this.loading = false

      if (getval.error) return this.$notify(getval, '')

      this.items = getval.data
      console.log('getProyeccionPeriodo.items', this.items)
    },

    async updateProyeccionPresupuesto (event, data) {
      const nuevoImporte = data.importe
      const nuevoProyecto = data.proyecto.id
      const nuevaUnidadAdmin = data.unidad_administrativa.id

      this.formValues.importe = nuevoImporte
      this.formValues.mes = data.mes
      this.formValues.proyecto_id = nuevoProyecto
      this.formValues.unidad_administrativa_id = nuevaUnidadAdmin

      this.updating = true
      const getval = await this.$store.dispatch('planeacionModule/editarProyeccion', {
        id: data.id,
        payload: this.formValues
      })
      this.updating = false

      this.onUpdatedImporte(getval)
    },

    async deleteProyeccionPresupuesto (event, data) {
      const proyeccionPresupuestoId = data.id

      this.updating = true

      const getval = await this.$store.dispatch(
        'planeacionModule/eliminarProyeccion',
        proyeccionPresupuestoId
      )

      if (getval.error) {
        this.$notify(getval, 'Eliminar proyeccion presupuestal')
        this.updating = false
        return
      }

      this.$notify(getval, 'Eliminar proyeccion presupuestal')
      this.updating = false

      this.$emit('on-updated', true)

      this.getProyeccionPeriodo()
    },

    onUpdatedImporte (getval) {
      this.$notify(getval, '')
      this.$emit('on-updated', true)
      this.getProyeccionPeriodo()
    },

    getListToSelect (list, keyId, keyText) {
      if (!list) return []

      return list.map(f => ({ value: f[keyId], text: f[keyText] }))
    }
  }
}
</script>
